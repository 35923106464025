import classNames from 'classnames';
import { redirect } from 'saddlebag-browser';

import BpkButton from '@skyscanner/backpack-web/bpk-component-button';
import {
  withButtonAlignment,
  withRtlSupport,
} from '@skyscanner/backpack-web/bpk-component-icon';
import LongArrowIcon from '@skyscanner/backpack-web/bpk-component-icon/sm/long-arrow-right';
import BpkPrice, { ALIGNS } from '@skyscanner/backpack-web/bpk-component-price';
import BpkText, {
  TEXT_STYLES,
} from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';

import type { ProviderInfo } from '@skyscanner-internal/falcon-common-types/types/ProviderRating';

import STYLES from './Footer.module.scss';

const LongArrowRight = withButtonAlignment(withRtlSupport(LongArrowIcon));

const onClick = (e: MouseEvent, props: ProviderInfo) => {
  e.stopPropagation();

  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.PROVIDER_RATING,
    component_action: COMPONENT_ACTION.PROVIDER_RATING.PROVIDER_RATING_CLICKED,
    supplier: {
      id: Number(props.providerId),
      name: props.providerName,
      overall_rating: props.ratingInfo?.overall || 0,
      cheapest_price_local: {
        currency: props?.cheapest?.currencyCode,
        amount: Number(props?.cheapest?.amount),
        unit: props?.cheapest?.unit
          ? props.cheapest.unit.toString().replace('UNIT_', '')
          : 'CENTI',
      },
      reviews: Number(props.ratingInfo?.reviews || 0),
    },
  });

  redirect(props.link, props.isOpenInNewTab);
};

const Footer = ({
  provider,
  strings,
}: {
  provider: ProviderInfo;
  strings: { [key: string]: string };
}) => {
  const { ariaLabelPrice, price, providerName } = provider;
  return (
    <div
      className={classNames(STYLES.Footer, {
        [STYLES.Footer__noPrice]: !price,
      })}
    >
      {price && (
        <div
          className={STYLES.Footer__price}
          aria-label={ariaLabelPrice}
          role="status"
        >
          <BpkPrice
            align={ALIGNS.left}
            leadingText={strings.from}
            price={price}
            trailingText={strings.perDay}
          />
        </div>
      )}
      <BpkButton
        link
        className={STYLES.Footer__view}
        onClick={(e: MouseEvent) => onClick(e, provider)}
      >
        <BpkText textStyle={TEXT_STYLES.label1}>
          {strings.view.replace(/@@provider@@/g, providerName)}
        </BpkText>
        <div className={STYLES.Footer__buttonArrow}>
          <LongArrowRight />
        </div>
      </BpkButton>
    </div>
  );
};

export default Footer;
