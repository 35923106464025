import { useEffect } from 'react';

import loadable from '@loadable/component';

import BpkMobileScrollContainer from '@skyscanner/backpack-web/bpk-component-mobile-scroll-container';
import BpkSectionHeader from '@skyscanner/backpack-web/bpk-component-section-header';

import {
  ACTION_TYPE,
  COMPONENT_NAME,
  LOAD_STATUS,
  COMPONENT_ACTION,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import DesktopScrollContainer from '../../common/DesktopScrollContainer/DesktopScrollContainer';
import { useMBDContext, buildMBDParam } from '../../common/MBD/useMBDContext';
import RatingsCard from '../RatingsCard/RatingsCard';

import type {
  ProviderRatingProps,
  ProviderInfo,
} from '@skyscanner-internal/falcon-common-types/types/ProviderRating';

import STYLES from './ProviderList.module.scss';

const IntersectionObserverWrapper = loadable(
  () => import('../../IntersectionObserverWrapper/IntersectionObserverWrapper'),
);

const logNextEvent = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.PROVIDER_RATING,
    component_action:
      COMPONENT_ACTION.PROVIDER_RATING.PROVIDER_RATING_NEXT_CLICKED,
  });
};

const logPrevEvent = () => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.PROVIDER_RATING,
    component_action:
      COMPONENT_ACTION.PROVIDER_RATING.PROVIDER_RATING_PREV_CLICKED,
  });
};

const ProviderList = (props: ProviderRatingProps) => {
  const {
    enableDesktopScroll,
    headerText,
    providerInfoList,
    strings,
    subHeaderText,
  } = props;
  const { rawComponentName, vertical } = useMBDContext();

  useEffect(() => {
    logMiniEventHelper({
      action_type: ACTION_TYPE.COMPONENT_LOADED,
      component_name: COMPONENT_NAME.PROVIDER_RATING,
      load_status: providerInfoList?.length
        ? LOAD_STATUS.LOADED
        : LOAD_STATUS.INVALID,
    });
  }, [providerInfoList]);

  if (!providerInfoList?.length) {
    return null;
  }

  return (
    <div
      className={STYLES.ProviderList}
      data-tracking-element-id={rawComponentName}
      data-tracking-common-params={buildMBDParam(rawComponentName, vertical)}
    >
      {headerText && (
        <div
          className={`${STYLES.ProviderList__header} ${
            enableDesktopScroll
              ? STYLES.ProviderList__header__remainScrolllerSpace
              : ''
          }`}
        >
          <BpkSectionHeader title={headerText} description={subHeaderText} />
        </div>
      )}
      {enableDesktopScroll ? (
        <DesktopScrollContainer
          nextLabel="next"
          prevLabel="prev"
          logPrevEvent={logPrevEvent}
          logNextEvent={logNextEvent}
        >
          {providerInfoList.map((provider: ProviderInfo, index: number) => (
            <RatingsCard
              key={`${provider.providerName}-desktop`}
              strings={strings}
              provider={provider}
              index={index}
            />
          ))}
        </DesktopScrollContainer>
      ) : (
        <div className={STYLES.ProviderList__desktop}>
          {providerInfoList.map((provider: ProviderInfo, index: number) => (
            <div
              key={`${provider.providerName}-desktop`}
              className={STYLES.ProviderList__desktop__cardContainer}
            >
              <RatingsCard
                strings={strings}
                provider={provider}
                index={index}
              />
            </div>
          ))}
        </div>
      )}

      <BpkMobileScrollContainer
        className={STYLES.ProviderList__mobileScroll}
        trailingIndicatorClassName={STYLES.Indicator}
      >
        <div className={STYLES.ProviderList__list}>
          {providerInfoList.map((provider: ProviderInfo, index: number) => (
            <div
              key={provider.providerName}
              className={STYLES.ProviderList__container}
            >
              <RatingsCard
                strings={strings}
                provider={provider}
                index={index}
              />
            </div>
          ))}
        </div>
      </BpkMobileScrollContainer>
    </div>
  );
};

export default (props: ProviderRatingProps) => (
  <IntersectionObserverWrapper
    onElementSeen={() =>
      logMiniEventHelper({
        action_type: ACTION_TYPE.COMPONENT_VISIBLE,
        component_name: COMPONENT_NAME.PROVIDER_RATING,
      })
    }
  >
    <ProviderList {...props} />
  </IntersectionObserverWrapper>
);
