import BpkCard from '@skyscanner/backpack-web/bpk-component-card';
import BpkRating, {
  RATING_SIZES,
} from '@skyscanner/backpack-web/bpk-component-rating';
import { TEXT_STYLES } from '@skyscanner/backpack-web/bpk-component-text';
import BpkText from '@skyscanner/backpack-web/bpk-component-text/src/BpkText';

import {
  ACTION_TYPE,
  COMPONENT_ACTION,
  COMPONENT_NAME,
} from '../../../constants';
import logMiniEventHelper from '../../../mini-event/logMiniEventHelper';
import { useMBDContext, buildMBDParam } from '../../common/MBD/useMBDContext';
import DetailRating from '../DetailRating/DetailRating';
import Footer from '../Footer/Footer';
import ProviderLogo from '../ProviderLogo/ProviderLogo';

import type { ProviderInfo } from '@skyscanner-internal/falcon-common-types/types/ProviderRating';

import STYLES from './RatingsCard.module.scss';

type RatingCardProps = {
  provider: ProviderInfo;
  strings: { [key: string]: string };
  index: number;
};

const onClick = (props: ProviderInfo, index: number) => {
  logMiniEventHelper({
    action_type: ACTION_TYPE.COMPONENT_CLICKED,
    component_name: COMPONENT_NAME.PROVIDER_RATING,
    component_action: COMPONENT_ACTION.PROVIDER_RATING.PROVIDER_RATING_CLICKED,
    supplier: {
      id: Number(props.providerId),
      name: props.providerName,
      overall_rating: props.ratingInfo?.overall || 0,
      cheapest_price_local: {
        currency: props?.cheapest?.currencyCode,
        amount: Number(props?.cheapest?.amount),
        unit: props?.cheapest?.unit
          ? props.cheapest.unit.toString().replace('UNIT_', '')
          : 'CENTI',
      },
      reviews: Number(props.ratingInfo?.reviews || 0),
      index,
    },
  });
};

const RatingsCard = ({ index, provider, strings }: RatingCardProps) => {
  const { ratingInfo } = provider;

  const overallRating = ratingInfo?.overall || 0;
  const reviewIsValid = ratingInfo?.reviews && ratingInfo?.reviews > 1;
  let ratingTitle = '';
  if (overallRating >= 4.9) {
    ratingTitle = strings.excellent;
  } else if (overallRating >= 4) {
    ratingTitle = strings.good;
  } else if (overallRating > 1) {
    ratingTitle = strings.okay;
  } else {
    ratingTitle = strings.no_rating;
  }
  const { rawComponentName, vertical } = useMBDContext();
  const mbdName = `${rawComponentName}_item`;

  return (
    <BpkCard
      className={STYLES.RatingsCard}
      onClick={() => onClick(provider, index)}
      href={provider.link}
      target={provider.isOpenInNewTab ? '_blank' : ''}
      rel={provider.isRelFollow ? '' : 'nofollow'}
      role="link"
      data-tracking-element-id={`${mbdName}_${index}`}
      data-tracking-common-params={buildMBDParam(mbdName, vertical)}
    >
      <div className={STYLES.RatingsCard__topSection}>
        <div className={STYLES.RatingsCard__logo}>
          <ProviderLogo provider={provider} />
        </div>
        {ratingInfo?.overall && ratingInfo?.overall > 1 ? (
          <div
            className={STYLES.RatingsCard__overallRating}
            aria-label={ratingInfo?.ariaLabel?.replace(
              '@@rating_text@@',
              ratingTitle,
            )}
            role="status"
          >
            <BpkRating
              ariaLabel={ratingTitle}
              title={ratingTitle}
              size={RATING_SIZES.large}
              subtitle={reviewIsValid ? ratingInfo?.reviewsNumber : ''}
              value={ratingInfo?.overall.toFixed(1)}
            />
          </div>
        ) : (
          // When rating is invalid, the rating number is not displayed
          <div
            className={STYLES.RatingsCard__invalidRating}
            aria-label={ratingInfo?.ariaLabel?.replace(
              '@@rating_text@@',
              ratingTitle,
            )}
            role="status"
          >
            <BpkText
              className={STYLES.RatingsCard__invalidRating__noRating}
              textStyle={TEXT_STYLES.heading5}
            >
              {ratingTitle}
            </BpkText>
            {reviewIsValid && (
              <BpkText className={STYLES.RatingsCard__invalidRating__noReviews}>
                {ratingInfo?.reviewsNumber}
              </BpkText>
            )}
          </div>
        )}
      </div>
      {ratingInfo && (
        <div className={STYLES.RatingsCard__middleSection}>
          <DetailRating ratingInfo={ratingInfo} />
        </div>
      )}
      <div className={STYLES.RatingsCard__divided} />
      <Footer strings={strings} provider={provider} />
    </BpkCard>
  );
};

export default RatingsCard;
